<template>
    <div class="form-box">
        <div class="form-row">
            <div class="input-label">脚本标题</div>
            <div>
                <el-input
                    v-model="add.title"
                    placeholder="请输入脚本标题"
                ></el-input>
            </div>
        </div>
        <div class="form-row">
            <div class="input-label">画布</div>
            <div>
                <el-radio-group v-model="canvasIndex" size="mini">
                    <el-radio
                        :label="index"
                        border
                        v-for="(item, index) in bg_size"
                        :key="index"
                        @change="selectCanvas(index)"
                        :disabled="item.disabled"
                    >
                        <span>{{ item.name }}</span>
                        <span v-if="index < bg_size.length - 1">
                            {{ item.width }}x{{ item.height }}
                        </span>
                    </el-radio>
                </el-radio-group>
            </div>
        </div>
        <div class="form-row" v-if="canvasIndex < bg_size.length - 1">
            <div class="input-label">分辨率</div>
            <div>
                <el-radio-group v-model="rateIndex" size="mini">
                    <el-radio
                        :label="index"
                        border
                        v-for="(item, index) in power"
                        :key="index"
                        @change="selectRate(item)"
                    >
                        {{ item.x }}*{{ item.y }}
                    </el-radio>
                </el-radio-group>
            </div>
        </div>
        <div class="canvas_set" v-else>
            <div class="label">设置尺寸</div>
            <div class="input_item">
                <el-input
                    v-model="add.canvas.width"
                    placeholder=""
                    size="mini"
                ></el-input>
                <div class="name">W</div>
            </div>
            <div style="line-height: 28px; padding: 0 10px">x</div>
            <div class="input_item">
                <el-input
                    v-model="add.canvas.width"
                    placeholder=""
                    size="mini"
                ></el-input>
                <div class="name">H</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "",

    data() {
        return {
            add: {
                title: "", //脚本标题
                canvas: {
                    //画布
                    width: "",
                    height: "",
                },
                rate: {
                    //分辨率
                    x: "",
                    y: "",
                },
            },
            title: "",
            canvasIndex: 0, //画布选择索引
            bg_size: [
                {
                    name: "竖版",
                    width: 9,
                    height: 16,
                    disabled: false,
                },
                {
                    name: "横版",
                    width: 16,
                    height: 9,
                    disabled: true,
                },
                {
                    name: "方形",
                    width: 1,
                    height: 1,
                    disabled: true,
                },
                {
                    name: "自定义",
                    width: "",
                    height: "",
                    disabled: true,
                },
            ],
            rateIndex: 0, //分辨率选择索引
            powers: [
                [
                    {
                        y: 1920,
                        x: 1080,
                    },
                    {
                        y: 1280,
                        x: 720,
                    },
                ],
                [
                    {
                        y: 1080,
                        x: 1920,
                    },
                    {
                        y: 720,
                        x: 1280,
                    },
                ],
                [
                    {
                        x: 1080,
                        y: 1080,
                    },
                    {
                        x: 720,
                        y: 720,
                    },
                ],
            ],

            power: [
                {
                    y: 1920,
                    x: 1080,
                },
                {
                    y: 1280,
                    x: 720,
                },
            ],
        };
    },

    mounted() {},
    created() {
        // this.powers[this.canvasIndex];
        this.add.rate.x = this.powers[this.canvasIndex][this.rateIndex].x;
        this.add.rate.y = this.powers[this.canvasIndex][this.rateIndex].y;
        // console.log(this.bg_size[this.canvasIndex].height);
        this.add.canvas.width = this.bg_size[this.canvasIndex].width;
        this.add.canvas.height = this.bg_size[this.canvasIndex].height;
    },
    methods: {
        selectCanvas(index) {
            // console.log(res);
            this.power = this.powers[index];
            // this.add.
            this.add.canvas.width = this.bg_size[index].width;
            this.add.canvas.height = this.bg_size[index].height;
        },
        selectRate(res) {
            console.log(res);
            this.add.rate.x = res.x;
            this.add.rate.y = res.y;
        },
    },
};
</script>

<style lang="less" scoped>
/deep/ .el-radio.is-bordered + .el-radio.is-bordered {
    margin-left: 0;
}
/deep/ .el-radio {
    margin-right: 10px;
}
/deep/ .el-radio__label {
    padding-right: 4px;
}
.form-box {
    margin: -10px 0;
    .form-row + .form-row {
        margin-top: 10px;
    }
    .input-label {
        margin-bottom: 10px;
    }
    /deep/ .el-radio--small.is-bordered {
        margin-right: 6px;
    }
    .canvas_set {
        display: flex;
        align-items: center;
        margin-top: 10px;
        .input_item {
            width: 100px;
            position: relative;
            .name {
                position: absolute;
                right: 10px;
                top: 0;
                line-height: 28px;
                font-weight: bold;
            }
        }
    }
}
</style>