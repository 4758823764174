import request from '@/utils/request'
// 创建脚本
export function createScript(data) {
    return request({
        url: '/api/app/ScriptInfo',
        method: 'post',
        data
    })
}
// // 修改脚本
// export function editScript(data) {
//     return request({
//         url: '/api/app/ScriptInfo',
//         method: 'PUT',
//         data
//     })
// }
// 修改脚本
export function editScript(data) {
    return request({
        url: '/api/app/ScriptInfo/update',
        method: 'post',
        data
    })
}
// 获取脚本
export function getScript(data) {
    // console.log(data);
    return request({
        url: '/api/app/ScriptInfo',
        method: 'GET',
        params: data
    })
}
// 获取脚本信息
export function getScriptInfo(id) {
    return request({
        url: `/api/app/ScriptInfo/${id}`,
        method: 'GET',
    })
}
// 删除脚本
// export function delScript(id) {
//     return request({
//         url: '/api/app/ScriptInfo',
//         method: 'DELETE',
//         headers: {
//             "Content-Type": "application/x-www-form-urlencoded",
//         },
//         data: {
//             idList: id
//         }
//     })
// }
// 删除脚本
export function delScript(id) {
    return request({
        url: '/api/app/ScriptInfo/batchDelete',
        method: 'post',
        // headers: {
        //     "Content-Type": "application/x-www-form-urlencoded",
        // },
        data: {
            ids: id
        }
    })
}