<template>
    <div
        class="script_content"
        v-infinite-scroll="load"
        :infinite-scroll-disabled="pages == where.current"
        v-loading="loading"
    >
        <div v-if="gruop.length" class="script_data">
            <div class="script-gruop">
                <div
                    class="script-list"
                    :class="item.id == selected.id ? 'active' : ''"
                    v-for="(item, index) in gruop"
                    :key="index"
                    @click="selectScript(item)"
                >
                    <div class="gruop-item">
                        <div class="info-box">
                            <div class="info-row">
                                <ul class="script-title-hd">
                                    <li
                                        class="script-title ellipsis"
                                        :title="item.title"
                                    >
                                        {{ item.title }}
                                    </li>
                                    <li
                                        class="open_btn"
                                        title="点击进入极速裂变"
                                    >
                                        <el-tag
                                            size="mini"
                                            effect="dark"
                                            style="margin-right: 6px"
                                            @click.native="goEdit(item)"
                                            >极速裂变</el-tag
                                        >
                                    </li>
                                </ul>
                            </div>
                            <!-- <div class="info-row">
                                <dl class="info-pian">
                                    <dt>片头+镜头1+镜头2+镜头结尾</dt>
                                    <dd>可生成N个视频</dd>
                                </dl>
                            </div> -->
                            <div class="info-row">
                                <ul>
                                    <li>播放量: 0</li>
                                    <li>点赞数: 0</li>
                                </ul>
                            </div>
                            <div class="info-row">
                                <ul>
                                    <li>评论数: 0</li>
                                    <li>曝光数: 0</li>
                                </ul>
                            </div>
                            <div class="info-row">
                                <div>
                                    创建时间:
                                    <!-- {{ item.createTime }} -->
                                    {{
                                        $moent(item.createTime).format(
                                            "YYYY-MM-DD HH:mm:ss"
                                        )
                                    }}
                                </div>
                            </div>
                            <div class="info-row">
                                <ul>
                                    <li>
                                        更新时间:
                                        {{
                                            $moent(item.updateTime).format(
                                                "YYYY-MM-DD HH:mm:ss"
                                            )
                                        }}
                                    </li>
                                    <li>
                                        <el-dropdown
                                            size="mini"
                                            v-if="mode != 'impo'"
                                        >
                                            <span class="el-dropdown-link">
                                                <i class="el-icon-more"></i>
                                            </span>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item
                                                    icon="el-icon-edit"
                                                    @click.native="
                                                        scriptEdit(item)
                                                    "
                                                    >脚本编辑</el-dropdown-item
                                                >
                                                <el-dropdown-item
                                                    icon="el-icon-share"
                                                    @click.native="goEdit(item)"
                                                    >视频裂变</el-dropdown-item
                                                >
                                                <el-dropdown-item
                                                    icon="el-icon-delete"
                                                    @click.native="
                                                        scriptDel(item, index)
                                                    "
                                                    >删除脚本</el-dropdown-item
                                                >
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page_box">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="count"
                    :current-page="where.current"
                    @current-change="page_change"
                >
                </el-pagination>
            </div>
        </div>
        <div v-else>
            <NULL_DATA
                :tis="'您还没创建脚本哦，点击前往创建'"
                :url="'/home/script/createscript'"
            />
        </div>
    </div>
</template>

<script>
import NULL_DATA from "@/components/null_data";
import { getScript, delScript } from "@/api/script";
export default {
    name: "script-gruop",
    props: ["mode"],
    components: {
        NULL_DATA,
    },
    data() {
        return {
            loading: false,
            load: false,
            gruop: [],
            req: {},
            get_script: 0, //0查询中 1查询完毕
            selected: {}, //选中的脚本
            // 总页数
            pages: 1,
            count: 0,
            where: {
                fileName: "", //标题
                size: 10, //每页记录数
                current: 1, //当前页
            },
        };
    },
    mounted() {},
    created() {
        this.getScriptAll();
    },
    methods: {
        page_change(val) {
            console.log(val);
            this.where.current = val;
            this.getScriptAll();
        },
        selectScript(info) {
            if (this.mode == "impo") {
                // 导入脚本
                this.selected = info;
                console.log(info);
            }
        },
        goEdit(res) {
            console.log(res);
            this.$router.push({
                path: "/make/editing",
                query: { sid: res.id },
                param: { sid: res.id },
            });
        },
        // 分页获取
        getScriptAll() {
            let scripts = [];
            this.get_script = true;
            this.get_script = 0;
            // this.gruop = [];
            this.loading = true;
            getScript(this.where).then((res) => {
                this.get_script = 1;
                this.pages = res.data.pages;
                this.count = res.data.total;
                this.loading = false;
                if (res.data.records) {
                    res.data.records.map((item) => {
                        item.canvas = JSON.parse(item.canvas);
                        item.rate = JSON.parse(item.rate);
                        item.gruop = JSON.parse(item.lensGroup);

                        scripts.push(item);
                        this.get_script = false;
                    });
                }

                this.gruop = res.data.records;
            });
        },
        scriptEdit(item) {
            //   this.$router.push("/home/script/createscript", { params: { id: 111 } });
            this.$router.push({
                name: "createScript",
                query: { mode: "1", id: item.id },
            });
        },
        scriptDel(item, index) {
            this.$confirm(
                `此操作将永久删除'${item.title}'脚本吗？, 是否继续?`,
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            ).then(() => {
                delScript(item.id).then((res) => {
                    if (res.code == 200) {
                        this.$message({
                            type: "success",
                            message: "删除成功!",
                        });

                        this.gruop.splice(index, 1);
                    }
                });
            });
        },
    },
};
</script>

<style lang="less" scoped>
.script-list.active .gruop-item {
    border-color: #3596fe;
}
.open_btn {
    cursor: pointer;
}
.script_content {
    font-size: 12px;
    padding: 0 5px;
    background-color: #fff;
    height: 100%;
    .script_data {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .script-gruop {
        flex: 1;
        height: 0;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        // justify-content: space-between;
        // & > div:first-child {
        //     flex: 1;
        //     height: 0;
        //     overflow-y: auto;
        //     padding: 10px;
        // }
    }
    .page_box {
        padding: 10px 0;
    }
    .script-list {
        flex: 0 0 20%;
        min-width: 300px;
        // width: 300px;
        box-sizing: border-box;
        color: #6f6d6d;
        // margin-bottom: 10px;
        padding: 6px;
    }
}
.gruop-item {
    // width: 300px;
    padding: 20px 16px;
    background-color: #f3f3f3;
    border-radius: 8px;
    line-height: 1.8;

    border: 2px solid transparent;
    .info-box {
        .script-title-hd {
            margin-bottom: 4px;
            .script-title {
                font-weight: bold;
                font-size: 13px;
                color: #2b2a2a;
            }
        }
        .info-row {
            .info-pian {
                padding-bottom: 10px;
            }
            ul {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}
</style>