<template>
    <div class="null_data">
        <el-empty>
            <el-button
                size="small"
                :description="description"
                type="primary"
                @click="openUrl"
                >{{ tis }}</el-button
            >
        </el-empty>
    </div>
</template>

<script>
export default {
    name: "NullData",
    props: {
        url: {
            default: "/index",
        },
        description: {
            default: "暂无数据",
        },
        tis: {
            default: "创建工程",
        },
    },
    data() {
        return {};
    },

    mounted() {},

    methods: {
        openUrl() {
            this.$router.push(this.url);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>