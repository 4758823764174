<style lang="less" scoped>
.title {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 20px;
}
</style>
<template>
    <div class="title">
        <div class="title-left">
            {{ title }}
        </div>
        <div class="title-right">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "title",
    props: {
        title: {
            type: String,
            default: "标题",
        },
    },
    data() {
        return {};
    },

    mounted() {},

    methods: {},
};
</script>