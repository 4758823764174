<style lang="less" scoped>
.my-script {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .title-box {
        margin-bottom: 10px;
        .form-box {
            display: flex;
        }
    }
    .script-box {
        flex: 1;
        height: 0;
        overflow: auto;
    }
}
</style>
<template>
    <div class="my-script">
        <div class="title-box">
            <COLUMN title="我的脚本">
                <template>
                    <div class="form-box">
                        <el-input
                            placeholder="请输入脚本标题"
                            suffix-icon="el-icon-search"
                            v-model="script_name"
                            size="mini"
                            @change="select_script"
                        >
                        </el-input>
                        <el-button
                            style="margin-left: 10px"
                            type="primary"
                            size="mini"
                            @click="dialogVisible = true"
                            >创建脚本</el-button
                        >
                    </div>
                </template>
            </COLUMN>
        </div>
        <div class="script-box">
            <SCRIPT_GRUOP ref="script_gruop" :key="Math.random()" />
        </div>

        <el-dialog
            title="创建脚本"
            :visible.sync="dialogVisible"
            width="450px"
            :before-close="handleClose"
        >
            <ADD_SCRIPT ref="addScript" />
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="createScript()"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import SCRIPT_GRUOP from "@/components/script/scriptGruop";
import ADD_SCRIPT from "@/components/script/addScript";
import COLUMN from "@/components/column";
export default {
    name: "VideoVue20201212Myscript",
    components: {
        SCRIPT_GRUOP,
        ADD_SCRIPT,
        COLUMN,
    },
    data() {
        return {
            dialogVisible: false,
            script_name: "",
        };
    },

    mounted() {
        // console.log(this.$refs.script_gruop);
        // this.$refs.script_gruop.where.fileName = 111;
    },

    methods: {
        createScript() {
            let addScriptData = this.$refs.addScript.add;
            addScriptData.mode = "2";
            this.$router.push({ name: "createScript", query: addScriptData });
        },
        handleClose() {
            this.dialogVisible = false;
        },
        select_script(value) {
            this.$refs.script_gruop.where.fileName = value;
            this.$refs.script_gruop.where.current = 1;
            this.$refs.script_gruop.gruop = [];
            this.$refs.script_gruop.getScriptAll();
        },
    },
};
</script>

<style lang="less" scoped>
</style>